<template>
  <div class="dimensions-form rounded border w-100">
    <quick-message
      class="mb-2"
      with-icon
      :show="hasMessage"
      type="error"
      title="Error"
      message="El peso volumetrico máximo es de 20kg"
    />
    <div class="d-flex flex-wrap">
      <div class="d-flex">
        <z-icon name="Box" size="20px" class="dimensions-icon" />
        <div class="title font-weight-bold">
          Dimensiones del paquete
        </div>
      </div>
      <div class="d-flex ml-auto align-items-center subtitle ">
        <z-icon name="Info" size="16"></z-icon>
        <div class="ml-1">Peso volumétrico máx. 20 kgs</div>
      </div>
    </div>
    <div class="d-md-flex flex-wrap card-margin w-100">
      <div class="d-flex align-items-center form-input">
        <z-input v-model="height" label="Alto" :state="validHeight" />
        <div class="append">cms</div>
      </div>
      <div class="d-flex align-items-center form-input">
        <z-input v-model="width" label="Ancho" :state="validWidth" />
        <div class="append">cms</div>
      </div>
      <div class="d-flex align-items-center form-input">
        <z-input v-model="large" label="Largo" :state="validLarge" />
        <div class="append">cms</div>
      </div>
      <div class="d-flex align-items-center form-input">
        <z-input v-model="weight" label="Peso" :state="validWeight" />
        <div class="append">grs</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as mutation from "@/store/modules/parcel/mutations-types";

export default {
  name: "DimensionsForm",

  data() {
    return {
      height: "",
      width: "",
      large: "",
      weight: "",
      validHeight: false,
      validWidth: false,
      validLarge: false,
      validWeight: false,
      volumetricWeight: 0
    };
  },

  computed: {
    hasMessage() {
      return this.volumetricWeight > 20;
    }
  },

  watch: {
    height: {
      handler(newVal, oldVal) {
        this.validateHeight(newVal, oldVal);
        this.validForm();
      }
    },
    width: {
      handler(newVal, oldVal) {
        this.validateWidth(newVal, oldVal);
        this.validForm();
      }
    },
    large: {
      handler(newVal, oldVal) {
        this.validateLarge(newVal, oldVal);
        this.validForm();
      }
    },
    weight: {
      handler(newVal, oldVal) {
        this.validateWeight(newVal, oldVal);
        this.validForm();
      }
    }
  },

  created() {
    this.setStateMeasures();
  },

  methods: {
    setStateMeasures() {
      const measure = this.$store.getters["parcel/getMeasures"];
      if (measure.width != null) {
        this.width = measure.width.toString();
      }
      if (measure.height != null) {
        this.height = measure.height.toString();
      }
      if (measure.length != null) {
        this.large = measure.length.toString();
      }
      if (measure.weight != null) {
        this.weight = measure.weight.toString();
      }
    },

    validateHeight(newVal, oldVal) {
      this.validHeight = false;
      if ((isNaN(newVal) || newVal <= 0) && newVal.length !== 0) {
        this.height = oldVal;
      }
      if (this.height.length === 0 || this.height <= 0) {
        this.validHeight = false;
      } else {
        this.validHeight = null;
      }
    },
    validateWidth(newVal, oldVal) {
      this.validWidth = false;
      if ((isNaN(newVal) || newVal <= 0) && newVal.length !== 0) {
        this.width = oldVal;
      }
      if (this.width.length === 0 || this.width <= 0) {
        this.validWidth = false;
      } else {
        this.validWidth = null;
      }
    },
    validateLarge(newVal, oldVal) {
      this.validLarge = false;
      if ((isNaN(newVal) || newVal <= 0) && newVal.length !== 0) {
        this.large = oldVal;
      }
      if (this.large.length === 0 || this.large <= 0) {
        this.validLarge = false;
      } else {
        this.validLarge = null;
      }
    },
    validateWeight(newVal, oldVal) {
      this.validWeight = false;
      if ((isNaN(newVal) || newVal <= 0) && newVal.length !== 0) {
        this.weight = oldVal;
      }
      if (this.weight.length === 0 || this.weight <= 0) {
        this.validWeight = false;
      } else {
        this.validWeight = null;
      }
    },
    validForm() {
      this.volumetricWeight = (this.height * this.width * this.large) / 5000;

      const valid =
        this.validHeight === null &&
        this.validWidth === null &&
        this.validLarge === null &&
        this.validWeight === null &&
        this.volumetricWeight <= 20;

      this.$store.commit(`parcel/${mutation.UPDATE_MEASURES}`, {
        valid,
        width: Number(this.width),
        height: Number(this.height),
        weight: Number(this.weight),
        length: Number(this.large),
        volumetricWeight: this.volumetricWeight
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dimensions-form {
  padding: 20px 20px 20px 12px;

  &.origin {
    background-color: $solitude-2;
  }

  .dimensions-icon {
    margin-right: 12px;
  }

  .title {
    font-size: 14px;
  }

  .subtitle {
    font-size: 12px;
    color: $comet;
    margin: 0;
  }

  .card-margin {
    margin-left: 26px;
  }

  .form-input {
    width: 30% !important;
    margin: 6px;
    .z-input {
      width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    .form-input {
      width: 100% !important;
      .z-input {
        width: 100%;
      }
    }
  }

  .append {
    width: 0;
    font-size: 12px;
    transform: translate(-40px);
    color: $comet;
  }

  @include media-breakpoint-down(md) {
    .append {
      width: 30px;
    }
  }
}
</style>
