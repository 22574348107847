<template>
  <div>
    <z-delivery-creation-modal
      v-if="!showConfirmation"
      title="Crear guía"
      subtitle="Recuerda que solo puedes solicitar servicios Same day antes de las 11:00 am"
      :service-type="PackageType"
      :enable-create="enableCreate"
      :creating="isCreating"
      @cancel="handleClose"
      @hide="handleClose"
      @create="handleCreate"
    >
      <quick-message
        class="mt-3"
        with-icon
        :show="showInfo"
        title="Información"
        type="info"
        message="Completa las dimensiones de tu paquete para poder cotizar tu envio"
      />
      <quick-message
        class="mt-3"
        title="Error"
        :message="errorMessage"
        :show.sync="showError"
        type="error"
        with-icon
      />
      <div class="d-md-flex package-creation-body">
        <div class="w-100 left-row">
          <delivery-mode />
          <div
            class="delivery-settings rounded border d-flex align-items-center justify-content-between mt-3"
          >
            <div class="font-weight-bold delivery-type-text w-100">
              Identificador o número de pedido
            </div>
            <z-input
              v-model="externalId"
              class="delivery-settings-input"
              label=""
              type="text"
              name="additional-option-external-id"
            />
          </div>
          <address-data-form variant="origin" class="mt-3" />
          <address-data-form variant="destination" class="mt-3" />
        </div>
        <div class="w-100 right-row">
          <package-creation-map />
          <dimensions-form class="mt-3" />
          <package-payment-method class="mt-3">
            <package-payment-info :info-items="infoItems" />
          </package-payment-method>
        </div>
      </div>
    </z-delivery-creation-modal>
    <package-confirmation-modal
      :show.sync="showConfirmation"
      :address="confirmationAddress"
      @get-shipping-label="getShippingLabel"
    />
  </div>
</template>

<script>
import AddressDataForm from "./AddressDataForm";
import DimensionsForm from "./DimensionsForm";
import PackageCreationMap from "./PackageCreationMap";
import PackagePaymentMethod from "./PackagePaymentMethod";
import PackagePaymentInfo from "./PackagePaymentInfo";
import PackageConfirmationModal from "./PackageConfirmationModal";
import DeliveryMode from "@/app/components/PackageCreation/DeliveryMode";
import ZDeliveryCreationModal from "@/app/components/ZDeliveryCreationModal";
import Parcels from "@/services/parcels";
import ServiceType from "@zubut/common/src/constants/services/type";
import * as mutation from "@/store/modules/parcel/mutations-types";
import _debounce from "lodash/debounce";

export default {
  name: "PackageCreation",

  components: {
    DeliveryMode,
    ZDeliveryCreationModal,
    AddressDataForm,
    DimensionsForm,
    PackageCreationMap,
    PackageConfirmationModal,
    PackagePaymentMethod,
    PackagePaymentInfo
  },

  data() {
    return {
      showError: false,
      errorMessage: "",
      showConfirmation: false,
      isCreating: false,
      PackageType: ServiceType.NUM_PARCEL_DELIVERY,
      confirmationAddress: {
        address: "",
        name: ""
      },
      total: 0,
      volumetricWeight: 0,
      loadingShippingLabel: false,
      shippingLabel: "",
      parcelId: ""
    };
  },

  computed: {
    infoItems() {
      const infoItems = [
        { title: "Total", data: this.formattedTotal },
        { title: "Peso volumétrico", data: this.formattedVolumetricWeight }
      ];
      return infoItems;
    },
    formattedTotal() {
      return `${this.total.toFixed(2)}  `;
    },
    formattedVolumetricWeight() {
      return `${this.volumetricWeight.toFixed(1)} kgs`;
    },
    deliverySelected() {
      return this.$store.getters["parcel/getDeliveryMode"];
    },
    externalId: {
      get() {
        return this.$store.getters["parcel/getAdditionalOptions"].externalId;
      },
      set(value) {
        this.$store.commit(
          `parcel/${mutation.SET_ADDITIONAL_OPTIONS_EXTERNAL_ID}`,
          value
        );
      }
    },

    enableCreate() {
      return this.$store.getters["parcel/isFormValid"] && !this.isCreating;
    },

    showInfo() {
      const hasInvalidDestinations = this.$store.getters[
        "parcel/hasInvalidDestinations"
      ];
      const hasMesuares = this.measures.valid;
      return !hasMesuares && !hasInvalidDestinations;
    },

    getVolumetricWeight() {
      return this.volumetricWeight;
    },

    measures() {
      return this.$store.getters["parcel/getMeasures"];
    }
  },

  watch: {
    measures: {
      deep: true,
      handler() {
        this.dispatchEstimation();
      }
    },

    showConfirmation(val) {
      if (val === false) {
        this.handleClose();
      }
    }
  },

  mounted() {
    this.dispatchEstimation = _debounce(this.getEstimation, 700);
  },

  methods: {
    handleClose() {
      this.$router.go(-1);
    },

    getEstimation() {
      const {
        valid,
        height,
        width,
        length,
        weight,
        volumetricWeight
      } = this.measures;
      if (valid) {
        this.volumetricWeight = volumetricWeight;
        if (this.volumetricWeight <= 20) {
          Parcels.estimation({
            height,
            width,
            length,
            weight,
            deliveryMode: this.deliverySelected
          })
            .then(res => {
              this.total = res.total;
            })
            .catch(err => {
              this.$captureError(err);
            });
        } else this.total = 0;
      } else {
        this.total = 0;
      }
    },

    getShippingLabel() {
      this.loadingShippingLabel = true;
      Parcels.shippingLabel(this.parcelId, "pdf")
        .then(({ base64Label }) => {
          const shippingLabel = `data:image/pdf;base64,${base64Label}`;
          let a = document.createElement("a");
          a.href = shippingLabel;
          a.download = `guia.pdf`;
          a.click();
        })
        .catch(error => {
          this.$captureError(error);
        })
        .finally(() => {
          this.loadingShippingLabel = false;
        });
    },

    handleCreate() {
      this.isCreating = true;
      this.$store
        .dispatch("parcel/request")
        .then(({ origin, parcel }) => {
          this.showConfirmation = true;
          this.parcelId = parcel.id;
          this.confirmationAddress.address = origin.address;
          this.confirmationAddress.name = origin.name;
        })
        .catch(err => {
          let message = err.message;
          // TODO: Remove this when message is returned in spanish
          if (err.name === "Not enough funds in the wallet") {
            message = "No cuentas con suficientes fondos en tu Wallet";
          }
          this.errorMessage = message;
          this.showError = true;
        })
        .finally(() => {
          this.isCreating = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.package-creation-body {
  margin-top: 12px;
}

.left-row {
  margin-right: 16px;
}

.right-row {
  margin-left: 16px;
}

@include media-breakpoint-down(lg) {
  .left-row {
    margin-right: 0;
  }

  .right-row {
    margin-left: 0;
    margin-top: 1rem;
  }
}

.delivery-settings {
  padding: 11px 11px;
  .delivery-type-text {
    font-size: 12px;
    padding-left: 10px;
  }
}

@include media-breakpoint-up(lg) {
  .delivery-settings-input {
    min-width: 253px;
  }
}

@include media-breakpoint-down(lg) {
  .delivery-settings {
    flex-direction: column;
  }

  .delivery-settings-input {
    margin: 12px;
    width: 100%;
  }
}
</style>
