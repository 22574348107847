<template>
  <div class="d-md-flex ">
    <package-info :content="content" class="package-info" />
    <div class="map rounded border w-100 ">
      <z-map-expandable :destinations="destinations" />
    </div>
  </div>
</template>

<script>
import PackageInfo from "../../components/PackageCreation/PackageInfo.vue";
import ZMapExpandable from "@zubut/common/src/components/ZMapExpandable";

export default {
  name: "PackageCreationMap",

  components: {
    PackageInfo,
    ZMapExpandable
  },
  data() {
    return {
      content: {
        title: "Guía",
        paragraphs: [
          "Generamos tu guía una vez realizado el pago.",
          "Imprime tu guía y pégala en tu paquete, recuerda tenerlo listo antes de que llegue el mensajero."
        ],
        iconName: "ZPL"
      }
    };
  },

  computed: {
    destinations() {
      return this.$store.getters["parcel/getMapDestinations"];
    }
  }
};
</script>

<style scoped lang="scss">
.package-info {
  margin-right: 16px;
}
@include media-breakpoint-down(md) {
  .map {
    margin-top: 16px;
  }
}
</style>
